// Third-party imports
import React from 'react';
// Local imports
import { CancellationWipoForm } from './cancellationWipoForm';

export const CancellationPage = () => {
  return (
    <section key="Cancellation">
      <asteroid-card>
        <CancellationWipoForm />
      </asteroid-card>
    </section>
  );
};
