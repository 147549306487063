// eslint-disable-next-line no-shadow
export enum LocalStorageKeys {
  USER = 'user',
}

const setLocalStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem };
