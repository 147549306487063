/* React imports */
import React, { forwardRef } from 'react';

/* Third-party imports */
import clsx from 'classnames';

import { TextareaStatus, TextareaProps } from './types';
import { Label } from '../label';

const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      id,
      label,
      helperText,
      status = TextareaStatus.Default,
      className,
      readOnly,
      labelProps,
      'data-testid': dataTestId = 'textarea-container',
      ...props
    },
    ref
  ) => {
    if (label && !id) {
      // eslint-disable-next-line no-console
      console.error('Textarea: `id` value must be specified when using `label`');
    }

    const textareaClasses = clsx(
      'form-control',
      status === TextareaStatus.Error && 'is-invalid',
      status === TextareaStatus.Success && 'is-valid',
      className
    );

    const helperTextClasses = clsx(
      status === TextareaStatus.Default && 'mt-small small color-muted',
      status === TextareaStatus.Error && 'invalid-feedback',
      status === TextareaStatus.Success && 'valid-feedback'
    );

    const labelClasses = clsx('form-label', labelProps?.className);

    return (
      <div>
        {label && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Label {...labelProps} className={labelClasses} htmlFor={id}>
            {label}
          </Label>
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <textarea
          {...props}
          ref={ref}
          className={textareaClasses}
          id={id}
          data-testid={dataTestId}
          readOnly={readOnly}
        />
        {helperText && <div className={helperTextClasses}>{helperText}</div>}
      </div>
    );
  }
);

// eslint-disable-next-line import/no-default-export
export default TextArea;
