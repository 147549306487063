// Third-party imports
import React from 'react';
// Wefox imports
import { i18n } from '@wefox/i18n';

export const Home = () => {
  return (
    <section key="Home">
      <asteroid-card>
        <h2>{i18n.getTranslation('POLICY_HOME_title')}</h2>
        <hr className="mb-gigantic" />
        <asteroid-accordion title={i18n.getTranslation('POLICY_HOME_cancellation-title')} icon="offer" is-open="false">
          <div slot="content">{i18n.getTranslation('POLICY_HOME_cancellation-description')}</div>
        </asteroid-accordion>
      </asteroid-card>
    </section>
  );
};
