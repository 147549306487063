// Third-party imports
import React, { StrictMode } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { Route } from 'react-router';
// Local imports
import { NotFound } from './notFoundPage';
import { Home } from './home';
import { CancellationPage } from './cancellation';
import { useAuthContext } from '../hooks/useAuthContext';

export const AppRoutes = () => {
  const { token } = useAuthContext();

  return (
    <StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="cancellation/:id" element={token ? <CancellationPage /> : <div>Loading..</div>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
};
