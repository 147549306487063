// Local imports
import { FetchMethods, fetchUtil } from '../index';
import { IPolicy } from './policy.interface';
import { IPolicyCancellationDate, IPolicyCancellationForm } from './policyCancellation.interface';

const getCancellationDate = async (id: string, reason?: string, token = ''): Promise<IPolicyCancellationDate> => {
  const url = `v3/policies/${id}/cancellation-date${reason && `?reason=${reason}`}`;
  const response = await fetchUtil({ url, method: FetchMethods.GET, token });

  return response.data;
};

const cancelPolicy = async (policyId: string, body: IPolicyCancellationForm, token: string): Promise<IPolicy> => {
  const url = `v1/policies/${policyId}/cancellations`;
  const response = await fetchUtil({
    url,
    method: FetchMethods.POST,
    body,
    token,
  });

  return response.data;
};

export { getCancellationDate, cancelPolicy };
