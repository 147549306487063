// Third-party imports
import 'regenerator-runtime/runtime';
// Local imports
import { fetchGetToken } from '../index';

const getOauthToken = async (): Promise<string> => {
  const data = await fetchGetToken();
  const payload = await data.data;

  return payload.access_token;
};

export { getOauthToken };
