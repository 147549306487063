// Third-party imports
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
// Local imports
import { AuthContextProvider } from './context/auth/authContext';
import { AppRoutes } from './pages';
import { ErrorFallback } from './components/errorFallback';
// Styles
import './styles/global.scss';

export const Root = (): JSX.Element => {
  const queryClient = new QueryClient();

  return (
    <React.StrictMode>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={(details) => {
          console.log('onReset', details);
        }}
      >
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AppRoutes />
          </QueryClientProvider>
        </AuthContextProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};
