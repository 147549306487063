// Third-party imports
import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
// Wefox imports
import { i18n } from '@wefox/i18n';
// Local imports
import { useAuthContext } from '../../../hooks/useAuthContext';
import { cancelPolicy, getCancellationDate } from '../../../api/policy/policy';
import { IPolicyCancellationDate } from '../../../api/policy/policyCancellation.interface';
import { subscribe, unsubscribe } from '../../../common/utils/events';
import { IPolicy } from '../../../api/policy/policy.interface';
import { IAPIError } from '../../../api';
import { Input, Textarea, TextareaStatus } from '../../../components';

export const CancellationWipoForm = () => {
  const { id: policyId } = useParams();
  const { token } = useAuthContext();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const {
    isLoading: cancellationDateIsLoading,
    error: cancellationDateError,
    data: cancellationDateData,
    refetch: cancellationDateRefetch,
  } = useQuery<IPolicyCancellationDate, AxiosError<IAPIError>>(
    ['cancellationDate'],
    () => getCancellationDate(policyId, '', token),
    {
      cacheTime: 1,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  const {
    isLoading: cancellationIsLoading,
    error: cancellationError,
    mutate: cancellationMutate,
    isSuccess: cancellationIsSuccess,
  } = useMutation<IPolicy, AxiosError<IAPIError>>({
    mutationFn: () => cancelPolicy(policyId, watch(), token),
  });

  useEffect(() => {
    subscribe('feedbackPrimaryCtaClick', () => cancellationDateRefetch());

    return () => {
      unsubscribe('feedbackPrimaryCtaClick', () => cancellationDateRefetch());
    };
  }, [cancellationDateRefetch]);

  if (cancellationDateIsLoading || cancellationIsLoading) return <h1>loading</h1>;

  if (cancellationDateError || cancellationError)
    return (
      <asteroid-feedback
        title={
          cancellationDateError
            ? i18n.getTranslation('POLICY_CANCELLATION_get-cancellation-date-error')
            : i18n.getTranslation('POLICY_CANCELLATION_post-policy-cancellation-error')
        }
        type="danger"
        description={cancellationDateError?.response?.data?.detail || cancellationError}
        primary-cta-label="Refresh"
      />
    );

  if (cancellationIsSuccess)
    return (
      <asteroid-feedback
        title={i18n.getTranslation('POLICY_CANCELLATION_post-policy-cancellation-success')}
        type="success"
        description={cancellationDateError?.response?.data?.detail || cancellationError}
      />
    );

  return (
    <div>
      <h2>
        {i18n.getTranslation('POLICY_CANCELLATION_WIPO-title')}: {policyId}
      </h2>
      <hr className="mb-gigantic" />
      <div className="d-flex flex-column justify-content-between h-100">
        <form onSubmit={handleSubmit(() => cancellationMutate())}>
          <div>
            <div className="mb-gigantic w-30">
              <Input
                id="cancellation-wipo-form-effective-date-input"
                type="date"
                label={i18n.getTranslation('INPUT_LABEL_effective-date')}
                defaultValue={cancellationDateData?.available_date?.split('T')[0]}
                {...register('effective_date')}
                disabled
              />
            </div>
          </div>
          <div>
            <p className="fw-bold">{i18n.getTranslation('INPUT_LABEL_feedback')}</p>
            <Textarea
              id="feedback"
              name="feedback"
              {...register('feedback', {
                required: {
                  value: true,
                  message: i18n.getTranslation('GLOBAL_form-error-required'),
                },
                minLength: {
                  value: 20,
                  message: `${i18n.getTranslation('GLOBAL_form-error-min-length')} 20`,
                },
                maxLength: {
                  value: 300,
                  message: `${i18n.getTranslation('GLOBAL_form-error-max-length')} 300`,
                },
              })}
              status={errors.feedback?.message ? TextareaStatus.Error : TextareaStatus.Default}
              helperText={errors.feedback?.message as string}
            />
            <hr className="mb-gigantic" />
          </div>
          <div>
            <wefox-button type="submit" disabled={!isDirty}>
              {i18n.getTranslation('BUTTON_submit')}
            </wefox-button>
          </div>
        </form>
      </div>
    </div>
  );
};
