import { useContext } from 'react';
import { AuthContext } from '../context/auth/authContext';

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error('useAuthContext must be used inside an AuthContextProvider');
  }

  return {
    token: context.state.token,
    dispatch: context.dispatch,
  };
};
