import { AuthReducersEnum } from './authConstants';
import { ActionMap } from '../common/actionMapGeneric';
import { LocalStorageKeys, removeLocalStorageItem, setLocalStorageItem } from '../../common/localStorage';

export type AuthPayload = {
  [AuthReducersEnum.LOGIN]: {
    token: string;
  };
  [AuthReducersEnum.LOGOUT]: never;
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export const authReducer = (state, action) => {
  switch (action.type) {
    case AuthReducersEnum.LOGIN: {
      const payload = action.payload as unknown as AuthPayload[AuthReducersEnum.LOGIN];
      setLocalStorageItem(LocalStorageKeys.USER, JSON.stringify(action.payload));

      return { token: payload.token };
    }

    case AuthReducersEnum.LOGOUT:
      removeLocalStorageItem(LocalStorageKeys.USER);

      return { token: null };
    default:
      return state;
  }
};
