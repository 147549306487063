/* React imports */
import React, { forwardRef } from 'react';

/* Third-party imports */
import clsx from 'classnames';

import { InputProps, InputSize, InputStatus } from './types';
import { Label } from '../label';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      label,
      helperText,
      status = InputStatus.Default,
      inputSize,
      className,
      readOnly,
      labelProps,
      'data-testid': dataTestId = 'input-container',
      ...props
    },
    ref
  ) => {
    if (label && !id) {
      // eslint-disable-next-line no-console
      console.error('Input: `id` value must be specified when using `label`');
    }

    const inputClasses = clsx(
      'form-control',
      status === InputStatus.Error && 'is-invalid',
      status === InputStatus.Success && 'is-valid',
      inputSize === InputSize.Small && 'form-control-sm',
      inputSize === InputSize.Large && 'form-control-lg',
      readOnly && 'form-control-plaintext',
      className
    );

    const helperTextClasses = clsx(
      status === InputStatus.Default && 'mt-small small color-muted',
      status === InputStatus.Error && 'invalid-feedback',
      status === InputStatus.Success && 'valid-feedback'
    );

    const labelClasses = clsx('form-label', labelProps?.className);

    return (
      <div>
        {label && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Label {...labelProps} className={labelClasses} htmlFor={id}>
            {label}
          </Label>
        )}

        <input {...props} ref={ref} className={inputClasses} id={id} data-testid={dataTestId} readOnly={readOnly} />
        {helperText && <div className={helperTextClasses}>{helperText}</div>}
      </div>
    );
  }
);
