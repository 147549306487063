import { DetailedHTMLProps, InputHTMLAttributes, LabelHTMLAttributes, ReactNode } from 'react';

export enum InputStatus {
  Default = 'default',
  Success = 'success',
  Error = 'error'
}

export enum InputSize {
  Small = 'sm',
  Large = 'lg'
}

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string | ReactNode;
  helperText?: string;
  status?: InputStatus;
  inputSize?: InputSize;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  'data-testid'?: string;
}
