import { DetailedHTMLProps, LabelHTMLAttributes, ReactNode, TextareaHTMLAttributes } from 'react';

export enum TextareaStatus {
  Default = 'default',
  Success = 'success',
  Error = 'error',
}

export interface TextareaProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string | ReactNode;
  helperText?: string;
  status?: TextareaStatus;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  'data-testid'?: string;
}
