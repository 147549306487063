// Third-party imports
import React from 'react';
// Wefox imports
import { i18n } from '@wefox/i18n';

export const NotFound = () => {
  return (
    <section key="not-found">
      <asteroid-feedback
        title={i18n.getTranslation('POLICY_NOT-FOUND_title')}
        description={i18n.getTranslation('POLICY_NOT-FOUND_description')}
      />
    </section>
  );
};
