import React, { ReactElement } from 'react';

interface Props {
  error?: Error;
}

export const ErrorFallback = ({ error }: Props): ReactElement<Props> => {
  return (
    <div data-testid="error-fallback">
      <div className="d-flex align-items-center flex-column">
        <div className="text-center">
          <h1 className="heading-lg">{error?.name}</h1>
          <p className="subtitle text-danger">{error?.message}</p>
        </div>
      </div>
    </div>
  );
};
