// Third-party imports
import axios, { AxiosPromise } from 'axios';

declare global {
  interface Window {
    wefoxConfig: {
      apiUrl: string;
    };
  }
}
const BASE_URL = window.wefoxConfig.apiUrl || '';

// eslint-disable-next-line no-shadow
export enum FetchMethods {
  GET = 'GET',
  POST = 'POST',
}

export interface IAPIError {
  title: string;
  detail: string;
  status: number;
}

interface FetchParams<BODY> {
  url: string;
  method: FetchMethods;
  token?: string;
  body?: BODY;
}

export function fetchUtil<BODY>(params: FetchParams<BODY>): Promise<AxiosPromise> {
  const { url, method, body, token } = params;

  return axios({
    url: `${BASE_URL}/${url}`,
    method,
    data: body ? JSON.stringify(body || null) : undefined,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`,
    },
  });
}

export function fetchGetToken(): Promise<AxiosPromise> {
  return axios({
    url: `/auth`,
    method: FetchMethods.POST,
    data: {},
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
}
