// Third-party imports
import React, { createContext, useReducer, useEffect, Dispatch } from 'react';
// Local imports
import { authReducer, AuthActions } from './authReducers';
import { AuthReducersEnum } from './authConstants';
import { getOauthToken } from '../../api/auth/auth';

export interface AuthContextInterface {
  token: string | null;
}

const AuthContext = createContext<{
  state: AuthContextInterface;
  dispatch: Dispatch<AuthActions>;
}>({
  state: { token: null },
  dispatch: () => null,
});

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    state: {
      token: null,
    },
  });

  useEffect(() => {
    getOauthToken()
      .then((token: string) => dispatch({ type: AuthReducersEnum.LOGIN, payload: { token } }))
      .catch((e) => {
        throw new Error(e);
      });

    return () => dispatch({ type: AuthReducersEnum.LOGOUT });
  }, []);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export { AuthContextProvider, AuthContext };
